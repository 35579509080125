export default class Nfse {
    constructor() {
        this.id = "";
        this.idIntegracao = "";
        this.number = "";
        this.customer = { id: "", content: "" };
        this.taxation = { id: "", content: "" };
        this.tributacao = {
            retido: false,
            codigoCidadeIncidencia: "",
            descricaoCidadeIncidencia: "",
            tipoTributacao: { id: "", content: "" },
            exigibilidade: { id: "", content: "" },
            aliquota: 0,
            cofins: 0,
            csll: 0,
            inss: 0,
            ir: 0,
            pis: 0,
            deducoes: 0,
        }

        this.messageRejection = "";
        this.urlPdf = "";
        this.urlXml = "";
        this.messageRejection = "";
        this.description = "";
        this.emails = [];
        this.value = 0;
        this.valueFinal = 0;
        this.valueTax = 0;
        this.status = "";
        this.rentId = "";
        this.rent = "";
    }
    update(data) {
        this.id = data.id;
        this.idIntegracao = data.idIntegracao;
        this.number = data.number;
        this.customer = { id: data.customerSupplier.id, content: data.customerSupplier.person.companyName };
        this.taxation = { id: data.taxation.id, content: data.taxation.discriminacao };
        this.description = data.description;
        this.messageRejection = data.messageRejection;
        this.status = data.status;
        this.emails = data.emails;
        this.urlPdf = data.urlPdf;
        this.urlXml = data.urlXml;

        this.tributacao = {
            retido: data.retido,
            codigoCidadeIncidencia: data.codigoCidadeIncidencia,
            descricaoCidadeIncidencia: data.descricaoCidadeIncidencia,
            tipoTributacao: { id: data.tipoTributacao, content: data.tipoTributacao },
            exigibilidade: { id: data.exigibilidade, content: data.exigibilidade },
            aliquota: data.aliquota,
            cofins: data.cofins,
            csll: data.csll,
            inss: data.inss,
            ir: data.ir,
            pis: data.pis,
            deducoes: data.deducoes,
        }

        this.value = data.value;
        this.valueTax = data.valueTax;
        this.valueFinal = data.valueFinal;

        if (data.rent != null) {
            var customer = `Nº ${data.rent.number} - ${data.rent.identification} - ${data.rent.customerSupplier.person.companyName}`;
            this.rent = { id: data.rent.id, content: customer };
        }
    }
}