<template>
  <div>
    <ViewTemplateWithSalveCancel :panel="panel">
      <div slot="content-main">
        <NfseCreateUpdate :formName="panel.formName" />
      </div>
    </ViewTemplateWithSalveCancel>
  </div>
</template>

<script>

import ViewTemplateWithSalveCancel from "@nixweb/nixloc-ui/src/component/template/ViewTemplateWithSalveCancel";
import NfseCreateUpdate from '../../../components/modules/tax-document/nfse/NfseCreateUpdate.vue'

export default {
  name: "NfseCreateUpdateView",
  components: {
    ViewTemplateWithSalveCancel,
    NfseCreateUpdate
  },
  data() {
    return {
      id: this.$route.params.id,
      panel: {
        module: "Nota Fiscal",
        title: "Nfse",
        formName: "nfseCreateUpdate",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
    };
  },
};
</script>
