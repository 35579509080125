<template>
    <div>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="10">
                <InputTag title="E-mail(s) de Notificação" placeholder="Digite um e-mail e pressione Enter"
                    :initialValue="nfse.emails" v-model="nfse.emails" :change="executeChange" />
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="2">
                <div class="div-import">
                    <Button _key="btnImport" title="Impor..E-mails" :disabled="!customerId"
                        :classIcon="'fa-solid fa-arrow-right-from-arc'" type="info" size="small"
                        :clicked="importEmails" />
                </div>
            </b-col>
        </b-row>
    </div>
</template>
<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import InputTag from "@nixweb/nixloc-ui/src/component/forms/InputTag";

import { mapMutations, mapActions } from "vuex";

export default {
    name: "InputTagEmail",
    props: ["customerId", "value", "change"],
    components: {
        Button,
        InputTag,
    },
    data() {
        return {
            urlGet: "/api/v1/shared/contact/import-all",
            nfse: {
                emails: []
            },
        }
    },
    created() {

    },
    methods: {
        ...mapActions("generic", ["getApi"]),
        ...mapMutations("generic", ["removeLoading"]),
        executeChange() {
            this.change();
        },
        importEmails() {
            let params = {
                url: this.urlGet,
                obj: {
                    id: this.customerId,
                },
            };
            this.getApi(params).then((response) => {
                let self = this;
                response.content.forEach(item => {
                    if (item.email) self.nfse.emails.push(item.email);
                });
                if (this.change) this.change();
                this.removeLoading(["btnImport"]);
            });
        },

    },
    watch: {
        'nfse.emails': {
            handler(emails) {
                this.$emit("input", emails);
            },
            deep: true,
        },
        value: {
            handler(emails) {
                this.$emit("input", emails);
                this.nfse.emails = this.value;
            },
            deep: true,
        },

    },
}
</script>
<style scoped>
.div-import {
    margin-top: 28px;
}
</style>