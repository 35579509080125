<template>
  <div class="margin">
    <br />
    <Button v-if="id" _key="btnNfseCreate" title="Nfse" classIcon="fas fa-plus-circle" type="primary" size="small"
      eventName="rentCreate" :clicked="newInvoice" />
    <DropdownOptionsNfs v-if="nfse.status == 1 || nfse.status == 3" type="nfse" :nf="nfse" />
    <div v-if="id">
      <br />
      <b-row>
        <b-col sm="5">
          <Badge v-if="nfse.number" :title="'Nº' + nfse.number.toString()" type="primary" size="medium" />
          <Badge :title="titleStatus()" :type="typeStatus()" size="small" />
        </b-col>
      </b-row>
      <br>
      <div class="div-message-rejection" v-if="nfse.status == 3">
        <Alert type="danger">
          <span>
            {{ nfse.messageRejection }}
          </span>
        </Alert>
      </div>
      <DisplayTotalization title="Nota" :value="nfse.value" />
      <DisplayTotalization title="Retenção ISS" _style="color: red" :value="nfse.valueTax" />
      <DisplayTotalization title="VALOR FINAL" :value="nfse.valueFinal" />
      <div v-if="nfse.status == 5">
        <Alert type="warning">
          <span>
            A nota está sendo <b>processada</b>, o retorno do município pode demorar <b>poucos segundos a vários
              minutos</b>
            dependendo da estabilidade de seus provedores, mas fique tranquilo(a), assim que autorizada
            enviaremos um <i class="fa-regular fa-envelope"></i> <b>e-mail
              notificando!</b>
          </span>
        </Alert>
      </div>
    </div>
    <Molded>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="4">
          <CustomerWithCreate :formName="formName" :disabled="disabled" v-model="nfse.customer" />
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="4">
          <Select title="Serviço" field="type" :formName="formName" :required="true" :disabled="disabled"
            url="/api/v1/tax-document/taxation/select-all" v-model="nfse.taxation" :showNewRegister="false"
            :heightNewRegister="250">
          </Select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="4">
          <Select title="Locação Vinculada" field="type" :formName="formName" :required="false"
            url="api/v1/rental/rent/select-all" v-model="nfse.rent" :showNewRegister="false" :heightNewRegister="250">
          </Select>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <TextArea title="Descrição" field="description" :disabled="disabled" :required="true"
            v-model="nfse.description" />
        </b-col>
      </b-row>
      <InputTagEmail v-model="nfse.emails" :customerId="nfse.customer.id" :change="saveInputTag" />
      <br />
      <Tributacao :formName="formName" :disabled="disabled" :nfse="nfse" v-model="nfse.tributacao" />
      <br />
      <b-row>
        <b-col sm="2">
          <InputDecimal title="Valor" :disabled="disabled" field="unitInsurance" :formName="formName" :required="false"
            :markFormDirty="false" :maxLength="5" type="float" v-model="nfse.value" />
        </b-col>
      </b-row>
    </Molded>
    <br />
  </div>
</template>

<script>


import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Badge from "@nixweb/nixloc-ui/src/component/layout/Badge.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import SelectStatic from "@nixweb/nixloc-ui/src/component/forms/SelectStatic";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import InputNumber from "@nixweb/nixloc-ui/src/component/forms/InputNumber";
import DropdownOptionsNfs from '../shared/DropdownOptionsNfs.vue'
import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import InputTagEmail from '../notification/InputTagEmail.vue'
import DisplayTotalization from "@nixweb/nixloc-ui/src/component/rental/DisplayTotalization";

import CustomerWithCreate from "../../rental/shared/CustomerWithCreate.vue";

import Tributacao from './Tributacao.vue'
import Nfse from "@/components/modules/tax-document/nfse/Nfse.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "NfseCreateUpdate",
  components: {
    Molded,
    Button,
    Badge,
    Select,
    Alert,
    RadioGroup,
    CustomerWithCreate,
    InputDecimal,
    DropdownOptionsNfs,
    TextArea,
    DisplayTotalization,
    SelectStatic,
    InputNumber,
    Tributacao, InputTagEmail
  },
  data() {
    return {
      id: this.$route.params.id,
      formName: "nfseCreateUpdate",
      nfse: new Nfse(),
      urlCreate: "/api/v1/tax-document/nfse/create",
      urlUpdate: "/api/v1/tax-document/nfse/update",
      urlGetById: "/api/v1/tax-document/nfse/get-by-id",
      tabIndex: 0,
    };
  },
  mounted() {
    if (this.id) this.getById();
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
    disabled() {
      if (this.nfse.status == 1) return true;
      if (this.nfse.status == 2) return true;
      if (this.nfse.status == 3) return false
      if (this.nfse.status == 4) return true;
      if (this.nfse.status == 5) return true;
      if (this.nfse.status == 6) return true;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", ["addEvent", "removeLoading", "hideVodal"]),
    ...mapMutations("validation", ["resetValidation", "removeFormDirty"]),
    titleStatus() {
      if (this.nfse.status == 1) return "Autorizada";
      if (this.nfse.status == 2) return "Concluída";
      if (this.nfse.status == 3) return "Rejeitada";
      if (this.nfse.status == 4) return "Cancelada";
      if (this.nfse.status == 5) return "Em processamento";
      if (this.nfse.status == 6) return "Pendente";
    },
    typeStatus() {
      if (this.nfse.status == 1) return "blue";
      if (this.nfse.status == 2) return "success";
      if (this.nfse.status == 3) return "danger";
      if (this.nfse.status == 4) return "danger";
      if (this.nfse.status == 5) return "info";
      if (this.nfse.status == 6) return "warning";
    },
    saveInputTag() {
      if (this.id) this.update();
    },
    create() {
      this.nfse.rentId = this.nfse.rent.id;
      let params = { url: this.urlCreate, obj: this.nfse };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.$router.replace({
            name: "nfseUpdate",
            params: { id: response.content.id },
          });
        }
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    update() {
      this.nfse.rentId = this.nfse.rent.id;
      let params = { url: this.urlUpdate, obj: this.nfse };
      this.putApi(params).then(() => {
        this.removeLoading(["saveSaveCancel"]);
      });
    },
    getById() {
      let params = { url: this.urlGetById, obj: { id: this.id } };
      this.getApi(params).then((response) => {
        this.nfse.update(response.content);
        let self = this;
        setTimeout(function () {
          self.removeLoading(["panel"]);
          self.removeFormDirty();
        }, 100);
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "saveSaveCancel") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }

        if (event.name == "customerOnRent") {
          if (!this.id) this.create();
          if (this.id) this.update();
        }

        if (event.name == "updateNf") {
          this.getById();
        }
      },
      deep: true,
    },
    'nfse.taxation': {
      handler(taxation) {
        if (this.nfse.description == "") {
          this.addEvent({ name: "taxation", data: taxation });
          this.nfse.description = taxation.description;
        }
      },
      deep: true,
    }
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}

.div-message-rejection {
  margin-bottom: 30px;
}
</style>
